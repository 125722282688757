<script lang="jsx">
import TojoyBreadcrumb from '@/components/common/breadcrumb'
import TojoyLeftNav from '@/components/common/left-nav'
export default {
  name: 'tojoy-inner-layout',
  components: {
    TojoyBreadcrumb,
    TojoyLeftNav
  },

  props: {
    breadcrumbList: {
      type: Array,
      default: () => []
    },
    isExchange: {
      type: Boolean,
      default: false,
    },
    customizeBreadcrumb: {
      type: Boolean,
      default: false
    },
    navList: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      defaultOptions: {
        breadcrumb: true,
        nav: true,
      },
    }
  },

  computed: {
    optionsVal() {
      return {...this.defaultOptions, ...this.options}
    },
  },

  methods: {
    breadcrumbSet(index, item) {
      this.$refs['tojoyBreadcrumb'].breadcrumbSet(index, item)
    },
    breadcrumbPush(item) {
      this.$refs['tojoyBreadcrumb'].breadcrumbPush(item)
    },
    navSetActive(name) {
      this.$refs['tojoyLeftnav'].navSetActive(name)
    },
    emitClickNav(navItem) {
      this.$emit('click-nav', navItem)
    }
  },

  render(h) {
    return (
      <div class="tojoy-inner-layout" >
        {
          this.optionsVal.breadcrumb ? (<tojoy-breadcrumb ref="tojoyBreadcrumb" data={this.breadcrumbList} customize={this.customizeBreadcrumb} is-exchange={this.isExchange} />) : ''
        }
        <div class="tojoy-inner-layout__main">
          {
            this.optionsVal.nav ? (<tojoy-left-nav ref="tojoyLeftnav"
            options={this.navList} on-click-nav={(navItem) => this.emitClickNav(navItem)} />) : ''
          }
          <router-view class="container" />
        </div>
      </div>
    )
  },
}
</script>

<style lang="scss" scoped>
.tojoy-inner-layout {
  // height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  &__main {
    display: flex;
    height: calc(100% - 77px);
    flex: 1;
    overflow: auto;
  }
  .container {
    flex: 1;
    box-sizing: border-box;
  }
}
</style>
