<script lang="jsx">
export default {
  name: 'tojoy-breadcrumb',

  props: {
    isExchange: {
      type: Boolean,
      default: false,
    },
    customize: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      breadcrumbs: [],
      baseRoute: {route: 'Home', title: '首页'}
    }
  },

  computed: {
    breadcrumbData() {
      if (this.customize) {
        return this.data
      } else {
        return this.breadcrumbs
      }
    },
  },

  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        if (this.customize) return

        const baseRouteName = 'Home'
        const allModules = this.$router.options.routes.find(r => r.name === baseRouteName)
        const currentModuleRoutes = allModules.children.find(c => c.name === this.$route?.meta?.module)?.children

        let routes = this.getTreeRoutemsg(currentModuleRoutes, this.$route?.meta?.id)

        // 支持重置title
        if(this.isExchange) {
          let newTitles = sessionStorage.getItem('exchange-title')
          if (newTitles) {
            newTitles = JSON.parse(newTitles)
            routes.map(r => {
              r.meta.title = newTitles[r.name] ?? r.meta.title
              return r
            })
          }
        }

        // watch监听不到$route的来源(即：from), 因此在浏览器缓存中暂存from, 方便回退到上一次记录的位置
        // 作此处理是为了兼容tab选项卡
        let currentRouteFrom = sessionStorage.getItem('current-route-from')
        if(currentRouteFrom) {
          currentRouteFrom = JSON.parse(currentRouteFrom)
          if(currentRouteFrom?.module && (currentRouteFrom?.module === to?.meta?.module)) {
            routes.map(item => {
              if(item.name === currentRouteFrom.name) {
                item.query = {...currentRouteFrom.query}
              }
              return item
            })
          }
        }

        let arr = []
        routes.forEach((v, k) => {
          arr.push({
            route: this.$route?.name === v.name ? '' : v.name, // 末节点不可点击
            params: v.params,
            query: v.query,
            title: v.meta.title,
          })
        })
        if (arr[0].route !== baseRouteName) {
          arr.unshift(this.baseRoute)
        }
        this.breadcrumbs = arr
      },
    }
  },

  methods: {
    breadcrumbSet(index, item) {
      this.breadcrumbData.splice(index, 1, item)
    },
    breadcrumbPush(item) {
      this.breadcrumbData.push(item)
    },
    /**
     * 获取路径
     * @param {Array} menuData 原生数据
     * @param {String} routeId 当前路由id
     */
    getTreeRoutemsg(menuData, routeId) {
      let routes = []
      const each = (recuData, sourceData) => {
        sourceData.map(sd => String(sd.meta?.id) === String(recuData.meta?.pid) && (each(sd, sourceData), routes.push(sd)))
      }
      const currentdata = menuData.find(node => String(node?.meta?.id) === String(routeId))
      if (currentdata) {
        each(currentdata, menuData)
      }

      // 加入当前节点
      routes.push(currentdata)
      return routes
    }
  },

  render() {
    return (
      <el-breadcrumb
        ref="breadcrumb"
        separator="/"
        separator-class="el-icon-caret-right"
        class="tojoy-breadcrumb"
      >
        {
          this.breadcrumbData.map(item => {
            const { route, params, query, title } = item
            return (
              route ? (
                <el-breadcrumb-item to={{name: route, params: params, query: query}}>
                  { title }
                </el-breadcrumb-item>
              ) : <el-breadcrumb-item >{title}</el-breadcrumb-item>
            )
          })
        }
      </el-breadcrumb>
    )
  }
}
</script>

<style lang="scss">
.tojoy-breadcrumb {
  background: $white;
  padding: 16px;
  box-shadow: inset 0px 1px 4px 0px $linecolor;
  border-bottom: 1px solid $linecolor;
  .el-breadcrumb {
    &__separator {
      color: $darkgrey;
      font-size: 12px;
      transform: scale(0.85);
    }
    &__inner a,
    &__inner.is-link {
      font-weight: 400;
      color: $darkgrey;
      &:hover {
        color: $blue;
      }
    }
    &__item:last-child {
      .el-breadcrumb__inner {
        color: $darkergrey;
      }
    }
  }
}
</style>
