<template>
  <nav :class="['tojoy-left-nav', { 'w-0': !show }]">
    <ul>
      <template v-for="item in list">
        <router-link :key="item.id" :to="item.path" tag="li" append :class="[{ active: item.active }]" class="nav-item">
          <div @click="$emit('click-nav', item)">
            <span :class="[' menu-icon icon iconfont ', item.icon]"></span>
            <span class="nav-item-child">
              {{ item.label }}
              <i v-show="item.badge == true" class="red-dot"></i>
            </span>
          </div>
        </router-link>
      </template>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'tojoy-left-nav',

  props: {
    options: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      list: this.options,
      show: true
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        const _findIndex = this.list.findIndex(item => item.name === newVal.name) !== -1
        this.show = _findIndex
        if (_findIndex) {
          this.navSetActive(newVal.name)
        }
      }
    }
  },

  created() {
    this.list.map((item, index) => {
      return {
        ...item,
        active: false
      }
    })
  },

  methods: {
    navSetActive(name) {
      const findIndex = this.list.findIndex(item => item.name === name)
      // 存在列表中则进行状态切换
      if (findIndex !== -1) {
        this.list.map((item, index) => {
          item.active = index === findIndex
          return item
        })

        this.$set(this.list, findIndex, { ...this.list[findIndex], active: true })
        // // 存储在浏览器缓存中，防止刷新浏览器时数据丢失
        // localStorage.setItem('current-nav-item', JSON.stringify(this.list.find(l => l.active)))
        // console.log("===current-nav-item==", localStorage.getItem('current-nav-item'))
      }
    }
  }
}
</script>

<style lang="scss">
.tojoy-left-nav {
  width: 200px;
  height: 100%;
  background: $white;
  border-right: 1px solid $linecolor;
  border-bottom: 1px solid $linecolor;
  transition: 0.1s;
  overflow: hidden;
  z-index: 0;
  &.w-0 {
    width: 0;
    transform: translateX(-200px);
  }
  .nav-item {
    height: 44px;
    line-height: 44px;
    background: $white;
    position: relative;
    cursor: pointer;
    padding-left: 20px;
    .menu-icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      margin-right: 4px;
    }
    &:hover {
      background: $grey650;
      transition: 0.2s;
      .menu-icon {
        color: $blue;
      }
    }
    &.active {
      background: $grey700;
      &:hover {
        background: $grey700;
      }
      .menu-icon {
        color: $blue;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 3px;
        height: 44px;
        left: 0;
        bottom: 0;
        top: 0;
        background: $blue;
      }
    }
    &-child {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      color: $lightblack;
      line-height: 14px;
      .red-dot {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $red;
        top: -1px;
        right: -3px;
      }
    }
  }
}
</style>
